import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import axios from 'axios';
import { ENTRYPOINT } from '../../config/entrypoint';
import { useNavigate } from 'react-router-dom';

const MyForm: React.FC = () => {
  // Declare state variables for form fields with types
  const [customerFile, setCustomerFile] = useState<string>('');
  const [container, setContainer] = useState<string>('');
  const [flashMessage, setFlashMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const message = localStorage.getItem('flashMessage');
    if (message) {
      setFlashMessage(message);
      setShowMessage(true);
      // Clear the message so it doesn't appear again on reload
      localStorage.removeItem('flashMessage');

      setTimeout(() => {
        setShowMessage(false); // Clear the message
      }, 10000); // 10 seconds
    }
  }, []);

  const location = useNavigate();

  // Handle form submission with typing for the event
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      // Define the payload with types
      const payload: { customerFile: string, container: string } = {
        customerFile,
        container
      };

      // Send form data to server-side controller
      const response = await axios.get(ENTRYPOINT + '/fcl_containers?number=' + payload.container + '&customerFile.code=' + payload.customerFile);

      // Handle successful submission
      if (response.data['hydra:totalItems'] === 1) {
        location('/evgm?container=' + payload.container + '&customerFile=' + payload.customerFile);
      }
    } catch (error) {
      // Handle error during submission
      console.error('Error:', error);
    }
  };

  const handleContainerChange = (event: ChangeEvent<HTMLInputElement>) => {
    function calculateCheckDigit(str: string) {
      const charToNumberMap: {[key: string]: number} = {
          'A': 10, 'B': 12, 'C': 13, 'D': 14, 'E': 15, 'F': 16, 'G': 17, 'H': 18, 'I': 19, 'J': 20,
          'K': 21, 'L': 23, 'M': 24, 'N': 25, 'O': 26, 'P': 27, 'Q': 28, 'R': 29, 'S': 30, 'T': 31,
          'U': 32, 'V': 34, 'W': 35, 'X': 36, 'Y': 37, 'Z': 38
      };

      let total = 0;
      const strRev = str.split('');

      for (let i = 0; i < strRev.length; i++) {
          let char = strRev[i];
          let val = isNaN(parseInt(char)) ? charToNumberMap[char.toUpperCase()] : parseInt(char);
          total += val * Math.pow(2, i);
      }

      let totalSumDividedBy11 = total / 11.0;
      let checkDigit = Math.floor(total - (Math.floor(totalSumDividedBy11) * 11));
      checkDigit = (checkDigit === 10) ? 0 : checkDigit;

      return checkDigit;
    }


    function validateAndFormatInput(e:ChangeEvent<HTMLInputElement>) {
      let text = e.target.value;
      text = text.replace(' ', '')
      // Check for paste event and if length is 11, insert slash at index 10
      if (text.length === 11 && text[10] !== '/') {
          text = text.slice(0, 10) + '/' + text.slice(10);
      }

      // Check for input event and if length is 10, insert slash at index 10
      if (e.type === 'input' && text.length === 10 && text[10] !== '/') {
          text = text.slice(0, 10) + '/';
      }

      // If text's length exceeds the format, reset it
      if (text.length > 12) {
          text = text.slice(0, 12);
      }

      // Validate the data
      var regex = /^[a-zA-Z]{4}\d{6}\/\d$/;
      if (text.length === 12 && (!regex.test(text) || !(calculateCheckDigit(text.slice(0, 10)) === parseInt(text.slice(11))))) {
          // add the 'invalid' class to the input field
          e.target.classList.add('invalid');
          e.target.classList.remove('valid');
      } else {
          e.target.classList.remove('valid');
          // remove the 'invalid' class if the input is valid
          if ((text.length === 12 && (regex.test(text) && (calculateCheckDigit(text.slice(0, 10)) === parseInt(text.slice(11)))))) {
              e.target.classList.add('valid');
          }
          e.target.classList.remove('invalid');
      }

      // Reflect the manipulated and validated text in the input field
      e.target.value = text;
    }

    validateAndFormatInput(event)

    setContainer(event.target.value)

  }

  return (
    <>
    <div className="flex min-h-full flex-1">
    {flashMessage && (
        <div className={`fixed z-50 top-0 left-0 right-0 bg-orange-500 text-white text-center py-4 transition-opacity duration-1000 ${showMessage ? 'bg-blue-500 opacity-100' : 'opacity-0'}`}>
          {flashMessage}
        </div>
      )}
    <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img
            className="h-25 w-auto"
            src="/images/logo-s90.png"
            alt="Your Company"
          />
          <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Générateur de déclaration eVGM
          </h2>
          <p className="mt-2 text-sm leading-6 text-gray-500">
            Vous ne trouvez pas vos références eVGM ? <br />Où les trouver ?{' '}
            <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
              Instruction
            </a>
          </p>
        </div>
        <div className="mt-10">
          <div>
            <form onSubmit={handleSubmit} method="POST" className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Référence Dossier
                </label>
                <div className="mt-2">
                  <input
                    id="customerFile"
                    name="customerFile"
                    type="text"
                    required
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={customerFile}
                    onChange={(e) => setCustomerFile(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Numéro de Conteneur
                </label>
                <div className="mt-2">
                  <input
                    id="container"
                    name="container"
                    type="text"
                    required
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={container}
                    onChange={handleContainerChange}
                 />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm leading-6">
                  <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Vous ne connaissez pas vos références eVGM ?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Valider
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className="relative hidden w-0 flex-1 lg:block">
      <img
        className="absolute inset-0 h-full w-full object-cover"
        src="https://images.unsplash.com/photo-1494412519320-aa613dfb7738?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80"
        alt=""
      />
    </div>
  </div>
</>

  );
};

export default MyForm;
