import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PagedCollection } from "../../interfaces/Collection";
import Form from "./Form";
import styled from "styled-components";
import { useRetrieve, useUpdate } from "../../hooks";
import { TResource } from "./type";
import { TError } from "../../utils/types";
import { useEffect, useState } from "react";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface ShowProps {
  retrieved: PagedCollection<TResource> | null;
  loading: boolean;
  error: TError;
  updateLoading: boolean;
  updateError: TError;
  updated: TResource | null;
  update: (item: TResource, values: Partial<TResource>) => any;
  reset: () => void;
}

const ShowView = ({
  error,
  loading,
  retrieved,
  update,
  updateError,
  updateLoading,
  updated,
  reset,
}: ShowProps) => {
  const items = updated
    ? [updated]
    : (retrieved && retrieved["hydra:member"]) || [];

  const [pageNumber, setPageNumber] = useState<number>(1);

  const [evgmMethod, setEvgmMethod] = useState<string | undefined>(
    items[0]?.evgmMethod?.valueOf() || "2"
  );

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setPageNumber(numPages);
  }

  useEffect(() => {
    setEvgmMethod(items[0]?.evgmMethod?.valueOf() || "2");
  }, [items]);

  const PDFDocumentWrapper = styled.div`
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  `;

  const MyDoc = (item: { pdfFile: string | undefined }) => (
    <PDFDocumentWrapper>
      <Document
        file={`data:application/pdf;base64,${item.pdfFile}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </PDFDocumentWrapper>
  );

  function downloadPDF(pdfFile: string | undefined) {
    const linkSource = `data:application/pdf;base64,${pdfFile}`;
    const downloadLink = document.createElement("a");
    const fileName = "evgm.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  function redirectToHome(): void {
    window.location.href = "/";
  }

  function validateEvgm(): void {
    update(items[0], { evgmValidatedAt: Math.floor(Date.now() / 1000) });
  }

  return (
    <div className="mx-2">
      {loading && <div className="alert alert-info">Loading...</div>}
      {error && <div className="alert alert-danger">{error.message}</div>}
      <div className="text-center text-gray-700 flex m-2 items-center justify-between flex-col sm:flex-row">
        <img
          className="h-14 w-auto"
          src="/images/logo-s90.png"
          alt="Your Company"
        />
        <div className="text-2xl sm:text-3xl">
          Création de la déclaration eVGM
        </div>
      </div>
      <div className="flex justify-around">
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="relative inline-flex gap-1 items-center rounded-l-md bg-gray-500 px-3 py-2 text-sm text-gray-200 ring-1 ring-inset ring-gray-300 hover:bg-gray-600 focus:z-10"
            onClick={redirectToHome}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
            Accueil
          </button>
          {items[0] && items[0].pdfFile && (
            <>
              <button
                type="button"
                className="relative -ml-px inline-flex gap-1 items-center bg-blue-400 px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-blue-500 focus:z-10"
                onClick={() => downloadPDF(items[0].pdfFile)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
                Télécharger
              </button>
              {items[0].evgmValidatedAt ? (
                <button
                  type="button"
                  disabled={true}
                  className="relative -ml-px gap-1 inline-flex items-center rounded-r-md bg-green-600 px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-green-600 focus:z-10"
                  onClick={validateEvgm}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                    />
                  </svg>
                  {new Intl.DateTimeFormat("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }).format(items[0].evgmValidatedAt * 1000)}
                </button>
              ) : (
                <button
                  type="button"
                  className="relative -ml-px gap-1 inline-flex items-center rounded-r-md bg-green-500 px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-green-600 focus:z-10"
                  onClick={validateEvgm}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                    />
                  </svg>
                  Valider la déclaration
                </button>
              )}
            </>
          )}
        </span>
      </div>
      <div className="flex sm:mx-auto mt-8 sm:max-w-6xl">
        {items.map((item) => (
          <div className="flex container gap-8 flex-col sm:flex-row">
            <Form
              onSubmit={(values) => {
                reset();
                const valuesDup = { ...values };
                valuesDup["shouldGeneratePdf"] = true;
                delete valuesDup["pdfFile"];
                delete valuesDup["customerFile"];
                valuesDup["evgmMethod"] = evgmMethod;
                update(item, valuesDup);
              }}
              evgmMethod={evgmMethod}
              setEvgmMethod={setEvgmMethod}
              error={updateError}
              reset={reset}
              initialValues={item}
              displayInfo={!item.pdfFile}
              changeable={!item.evgmValidatedAt}
            />

            {item.pdfFile && (
              <div className="relative sm:w-1/2">
                <MyDoc pdfFile={item.pdfFile} />
              </div>
            )}
            {item.evgmValidatedAt && (
              <div className="absolute top-56 right-52 text-green-500 -rotate-[17deg]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-36 h-36"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>{" "}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Evgm = () => {
  const [searchParams] = useSearchParams();
  const container = searchParams.get("container");
  const customerFile = searchParams.get("customerFile");
  const navigate = useNavigate();

  let missingParam = '';
  if (!container) missingParam = 'Conteneur';
  if (!customerFile) missingParam = missingParam ? `${missingParam} et Numéro de dossier` : 'Numéro de dossier';

  if (missingParam) {
    // Set a flash message
    localStorage.setItem('flashMessage', `Le paramètre ${missingParam} est manquant`);
    // Redirect to home page
    navigate('/');
  }

  const id =
    "/solas-api/fcl_containers?number=" +
    container +
    "&customerFile.code=" +
    customerFile;

  const { retrieved, loading, error } = useRetrieve<TResource>(id);
  const {
    updated,
    update,
    reset,
    loading: updateLoading,
    error: updateError,
  } = useUpdate<TResource>();

  return (
    <ShowView
      retrieved={retrieved}
      loading={loading}
      error={error}
      updateLoading={updateLoading}
      updateError={updateError}
      updated={updated}
      update={update}
      reset={reset}
    />
  );
};

export default Evgm;
