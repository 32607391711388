import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import TextField from "../TextField";

import { TResource, TResourceUpdate } from "./type";
import { SubmissionError, TError } from "../../utils/types";
import RadioButton from "../RadioButton";

interface FormProps {
  onSubmit: (item: Partial<TResource>) => any;
  initialValues?: Partial<TResource>;
  error?: TError;
  displayInfo?: boolean;
  changeable?: boolean;
  evgmMethod?: string;
  setEvgmMethod: Dispatch<SetStateAction<string | undefined>>;
  reset: () => void;
}

const Form = ({
  onSubmit,
  error,
  reset,
  initialValues,
  displayInfo,
  evgmMethod,
  changeable,
  setEvgmMethod,
}: FormProps) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<TResource>({
    defaultValues: initialValues
      ? {
          ...initialValues,
        }
      : undefined,
  });

  useEffect(() => {
    if (error instanceof SubmissionError) {
      Object.keys(error.errors).forEach((errorPath) => {
        if (errors[errorPath as keyof TResourceUpdate]) {
          return;
        }
        setError(errorPath as keyof TResourceUpdate, {
          type: "server",
          message: error.errors[errorPath],
        });
      });

      reset();
    }
  }, [error, errors, reset, setError]);

  const handleChangeEvgmMethod = (e: ChangeEvent<HTMLInputElement>): void => {
    setEvgmMethod(e.target.value);
  };

  const transformNumberValue = (event: ChangeEvent<HTMLInputElement>): void => {
    let number = event.target.value;
    number = number.replace(' ', '');
    // number = number.replace(',', '.');
    event.target.value = number;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex ${displayInfo ? "flex-col sm:flex-row" : "sm:w-1/2"}`}
    >
      <div className="flex flex-col gap-4 h-full container mx-auto">
        <div className="flex gap-8 flex-col-reverse sm:flex-row">
          <div className={`border ${displayInfo ? "sm:w-1/2" : "w-full"}`}>
            <div className="bg-gray-500 text-center text-gray-200">
              Informations
            </div>
            <div className="flex flex-col sm:flex-row gap-2 px-4 my-2 ">
              <div className="sm:w-1/2 mx-2">
                <TextField
                  register={register}
                  name="evgmShipperName"
                  label="Chargeur/Exportateur"
                  placeholder=""
                  type="text"
                  errors={errors}
                />
              </div>
              <div className="sm:w-1/2 mx-2">
                <TextField
                  register={register}
                  name="evgmGoodName"
                  label="Nature des marchandises"
                  placeholder=""
                  type="text"
                  errors={errors}
                />
              </div>
            </div>
          </div>
          <div
            className={`border border-s-4 px-4 rounded border-s-blue-400 ${
              displayInfo ? "sm:w-1/2" : "hidden"
            }`}
          >
            <div className="text-blue-500 font-medium text-xl mt-4 mb-2">
              Informations
            </div>
            <p className="text-sm">
              Pré-rempli par notre système, vous pouvez au besoin
              modifier/préciser ces informations.
            </p>
          </div>
        </div>
        <div className="flex gap-8 flex-col-reverse sm:flex-row">
          <div className={`border ${displayInfo ? "sm:w-1/2" : "w-full"}`}>
            <div className="bg-gray-500 text-center text-gray-200">eVGM</div>
            <div className="flex flex-col gap-2 px-4 my-2">
              <div>
                <RadioButton
                  register={register}
                  name="evgmMethod"
                  value="1"
                  label="méthode n°1 (Pesage du conteneur empoté grâce à un instrument de pesage approprié)"
                  errors={errors}
                  handleChange={handleChangeEvgmMethod}
                  checked={evgmMethod === "1"}
                />
              </div>

              {evgmMethod === "1" && (
                <div className="sm:w-1/2 mx-2">
                  <TextField
                    register={register}
                    name="evgmTotalMass"
                    label="none"
                    placeholder="0.0"
                    type="text"
                    errors={errors}
                    prefix="Poids global"
                    suffix="kg"
                    required={evgmMethod === "1"}
                    handleChange={transformNumberValue}
                    />
                </div>
              )}
              <div>
                <RadioButton
                  register={register}
                  name="evgmMethod"
                  value="2"
                  label="méthode n°2 (Addition des masses et de la tare du Conteneur)"
                  errors={errors}
                  handleChange={handleChangeEvgmMethod}
                  checked={evgmMethod === "2"}
                />
              </div>
              {evgmMethod === "2" && (
                <>
                  <div className="flex flex-col sm:flex-row gap-2 mx-2">
                    <div className="sm:w-1/2">
                      <TextField
                        register={register}
                        name="evgmGoodMass"
                        label="none"
                        placeholder="0.0"
                        type="text"
                        errors={errors}
                        prefix="Poids march."
                        suffix="kg"
                        required={evgmMethod === "2"}
                        handleChange={transformNumberValue}
                      />
                    </div>
                    <div className="sm:w-1/2">
                      <TextField
                        register={register}
                        name="evgmPackingMass"
                        label="none"
                        placeholder="0.0"
                        type="text"
                        errors={errors}
                        prefix="Poids emballages"
                        suffix="kg"
                        required={evgmMethod === "2"}
                        handleChange={transformNumberValue}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row gap-2 mx-2">
                    <div className="sm:w-1/2">
                      <TextField
                        register={register}
                        name="evgmPaletMass"
                        label="none"
                        placeholder="0.0"
                        type="text"
                        errors={errors}
                        prefix="Poids palettes"
                        suffix="kg"
                        required={evgmMethod === "2"}
                        handleChange={transformNumberValue}
                      />
                    </div>
                    <div className="sm:w-1/2">
                      <TextField
                        register={register}
                        name="evgmContainerMass"
                        label="none"
                        placeholder="0.0"
                        type="text"
                        errors={errors}
                        prefix="Tare du conteneur"
                        suffix="kg"
                        required={evgmMethod === "2"}
                        handleChange={transformNumberValue}
                        />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={`border border-s-4 px-4 rounded border-s-blue-400 ${
              displayInfo ? "sm:w-1/2" : "hidden"
            }`}
          >
            <div className="text-blue-500 font-medium text-xl mt-4 mb-2">
              EVGM
            </div>
            <p className="text-sm">
              2 types de méthode sont autorisés :<br />-{" "}
              <strong>méthode n°1</strong> : Pesage du conteneur empoté grâce à
              un instrument de pesage approprié
              <br />- <strong>méthode n°2</strong> : Addition des masses et de
              la tare du Conteneur
            </p>
            <p className="text-sm">
              Pour des raisons de responsabilité,{" "}
              <u>ces données ne peuvent pas être pré-remplies</u>, merci de vous
              assurez de leur exactitude.
            </p>
          </div>
        </div>
        <div className="flex gap-8 flex-col-reverse sm:flex-row">
          <div className={`border ${displayInfo ? "sm:w-1/2" : "w-full"}`}>
            <div className="bg-gray-500 text-center text-gray-200">
              Conteneur
            </div>
            <div className="flex flex-col sm:flex-row gap-2 px-4 my-2">
              <div className="sm:w-1/3 mx-2">
                <TextField
                  register={register}
                  name="evgmContainerType"
                  label="Type de conteneur"
                  placeholder=""
                  type="text"
                  errors={errors}
                  required={true}
                />
              </div>
              <div className="sm:w-1/3 mx-2">
                <TextField
                  register={register}
                  name="evgmContainerNumber"
                  label="Numéro du conteneur"
                  placeholder=""
                  type="text"
                  errors={errors}
                  required={true}
                />
              </div>
              <div className="sm:w-1/3 mx-2">
                <TextField
                  register={register}
                  name="evgmSeals"
                  label="Scellés"
                  placeholder=""
                  type="text"
                  errors={errors}
                  required={true}
                />
              </div>
            </div>
          </div>
          <div
            className={`border border-s-4 px-4 rounded border-s-blue-400 ${
              displayInfo ? "sm:w-1/2" : "hidden"
            }`}
          >
            <div className="text-blue-500 font-medium text-xl mt-4 mb-2">
              Conteneur
            </div>
            <p className="text-sm">
              Pour accélérer la saisie, nous avons pré-saisi le type et le
              numéro de conteneur.
            </p>
          </div>
        </div>
        <div className="flex gap-8 flex-col-reverse sm:flex-row">
          <div className={`border ${displayInfo ? "sm:w-1/2" : "w-full"}`}>
            <div className="bg-gray-500 text-center text-gray-200">
              Signature
            </div>
            <div className="flex flex-col sm:flex-row gap-2 px-4 my-2">
              <div className="sm:w-1/2 mx-2">
                <TextField
                  register={register}
                  name="evgmContactName"
                  label="Responsable autorisé"
                  placeholder=""
                  type="text"
                  errors={errors}
                  required={true}
                />
              </div>
              <div className="sm:w-1/2 mx-2">
                <TextField
                  register={register}
                  name="evgmContactInfo"
                  label="Tel ou Mail"
                  placeholder=""
                  type="text"
                  errors={errors}
                  required={true}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-2 px-4 sm:items-end my-2">
              <div className="sm:w-1/3 mx-2">
                <TextField
                  register={register}
                  name="evgmPlaceOfIssue"
                  label="Fait à"
                  placeholder=""
                  type="text"
                  errors={errors}
                  required={true}
                />
              </div>
              <div className="sm:w-1/3 mx-2">
                <TextField
                  register={register}
                  name="evgmDateOfIssue"
                  label="le"
                  placeholder=""
                  type="text"
                  errors={errors}
                  required={true}
                />
              </div>
              <div className="sm:w-1/3 mx-2">
                {changeable ? (
                  <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Enregister et prévisualiser
                  </button>
                ) : (
                  <p>Déclaration Validée</p>
                )}
              </div>
            </div>
          </div>
          <div
            className={`border border-s-4 px-4 rounded border-s-blue-400 ${
              displayInfo ? "sm:w-1/2" : "hidden"
            }`}
          >
            <div className="text-blue-500 font-medium text-xl mt-4 mb-2">
              Signature
            </div>
            <p className="text-sm">
              Pour qu'elle soit valide, la déclaration doit être{" "}
              <strong>signée</strong> (Nom du représentant légal en majuscule)
              et <strong>datée</strong>.
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
