import {
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";

interface FieldProps<TFieldValues extends FieldValues> {
  register: UseFormRegister<TFieldValues>;
  name: Path<TFieldValues>;
  label?: string;
  placeholder: string;
  type: string;
  step?: string;
  required?: boolean;
  prefix?: string;
  suffix?: string;
  errors: Partial<DeepMap<TFieldValues, FieldError>>;
  handleChange?: (value: any) => void;
}

const TextField = <TFieldValues extends FieldValues>({
  register,
  name,
  label,
  placeholder,
  type,
  prefix,
  suffix,
  step,
  required = false,
  errors,
  handleChange,
}: FieldProps<TFieldValues>) => {
  const inputProps: { className: string; "aria-invalid"?: boolean } = {
    className: "form-control",
  };

  if (errors[name]) {
    inputProps.className += " border-pink-500 text-pink-600";
    inputProps["aria-invalid"] = true;
  }

  if (!errors[name]) {
    inputProps.className += " is-valid";
  }

  return (
    <div className="flex flex-col">
      {"none" !== label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label || name}
        </label>
      )}

      <div className="flex relative mt-2 rounded-md shadow-sm">
        {prefix && (
          <span className="bg-slate-300 inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
            {prefix}
          </span>
        )}
        <input
          id={name}
          placeholder={placeholder}
          type={type}
          step={step}
          {...inputProps}
          {...register(name, {
            required: required ? "Champs requis" : false,
            // valueAsNumber: type === "number",
          })}
          onChange={handleChange}
          className={`block w-full min-w-0 flex-1 ${
            prefix ? "rounded-none rounded-r-md text-end pe-8" : "rounded"
          } border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
        />
        {suffix && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400">
            {suffix}
          </div>
        )}
      </div>
      {errors[name] && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {errors[name]?.message}
        </p>
      )}
    </div>
  );
};

export default TextField;
