import React from 'react';
import './App.css';
import MyForm from './components/home/Home';


function App() {
  return (
    <MyForm></MyForm>
      );
 }

export default App;
