import {
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";

interface FieldProps<TFieldValues extends FieldValues> {
  register: UseFormRegister<TFieldValues>;
  name: Path<TFieldValues>;
  label?: string;
  value?: string;
  checked: boolean;
  step?: string;
  required?: boolean;
  errors: Partial<DeepMap<TFieldValues, FieldError>>;
  handleChange?: (value: any) => void;
}

const RadioButton = <TFieldValues extends FieldValues>({
  register,
  name,
  label,
  value,
  checked,
  step,
  required = false,
  errors,
  handleChange,
}: FieldProps<TFieldValues>) => {
  const inputProps: { className: string; "aria-invalid"?: boolean } = {
    className: "form-control",
  };

  if (errors[name]) {
    inputProps.className += " border-pink-500 text-pink-600";
    inputProps["aria-invalid"] = true;
  }

  if (!errors[name]) {
    inputProps.className += " is-valid";
  }

  return (
    <div className="flex flex-col">
      <div className="flex relative mt-2 rounded-md shadow-sm items-center gap-1">
        <input
          id={name + value}
          type="radio"
          value={value}
          step={step}
          {...register(name)}
          onChange={handleChange}
          className=""
          checked={checked}
        />
        <label
          htmlFor={name + value}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label || name}
        </label>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"></div>
      </div>

      {errors[name] && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {errors[name]?.message}
        </p>
      )}
    </div>
  );
};

export default RadioButton;
